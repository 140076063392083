import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        Disclaimer, this website is still being developed. Any data that can be found on this website is confidential. Please do not distribute. This data is under review process, actual data will be provided upon publication.
        </p>
        <p className='footer-subscription-text'>
        Please contact <a href="mailto:martina.stromvik@mcgill.ca">Dr. Martina Stromvik</a> for any further inquiries.
        </p>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
              <a href="mailto:martina.stromvik@mcgill.ca">Dr. Martina Stromvik</a>
              <a href="mailto:ilayda.bozan@mail.mcgill.ca">Ilayda Bozan</a>
              <a href="mailto:sai.achakkagari@mcgill.ca">Sai Reddy Achakkagari</a>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items2'>
            <h2>Recent Publications</h2>
            <p><a href="https://www.tandfonline.com/doi/pdf/10.1080/23802359.2021.1883486">The complete plastome sequences of nine diploid potato clones</a></p>
            <span>(Achakkagari et al., 2021)</span>
            <p><a href="https://www.tandfonline.com/doi/pdf/10.1080/23802359.2021.1886016">Complete mitogenome assemblies from a panel of 13 diverse potato taxa</a></p>
            <span>(Achakkagari et al., 2021)</span>
            <p><a href="https://doi.org/10.1093/dnares/dsab009">The complete mitogenome assemblies of 10 diploid potato clones reveal recombination and overlapping variants</a></p>
            <span>(Achakkagari et al., 2021)</span>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              PGDP
              <i class="fas fa-dna"></i>
            </Link>
          </div>
          <small class='website-rights'>PGDP @2023</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
