import React, {useState, useEffect} from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [, setButton] = useState(true);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    function showButton() {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    }
  
    useEffect(() => {
      showButton();
    }, []);  

    window.addEventListener('resize', showButton)

    return(
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo">
                        PGDP 
                        <i class="fas fa-dna"></i>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link  to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link 
                                to="/collaborators" 
                                className="nav-links" 
                                onClick={closeMobileMenu}
                            >
                                Collaborators
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link 
                                to="/JBrowse" 
                                className="nav-links" 
                                onClick={closeMobileMenu}
                            >
                                JBrowse
                            </Link>
                        </li>
*/                        <li className="nav-item">
                            <Link 
                                to="/download" 
                                className="nav-links" 
                                onClick={closeMobileMenu}
                            >
                                Download
                            </Link>
                        </li>

                    </ul>
                </div>
            </nav>
        </>
    )
}
export default Navbar
