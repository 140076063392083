import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(10),
    },
  },
}));

export default function BasicButtonGroup() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup variant="text" color="black" aria-label="text primary button group">
        <Button 
            href='https://jbrowse.potatogenomeportal.org'
            variant="text" 
            color="secondary"
            size="large">
            Nuclear Genome Browser
            </Button>
            <Button 
            href='https://jbrowse.potatogenomeportal.org/?config=mito_config.json'
            variant="text" 
            color="secondary"
            size="large">
            Mitogenome Browser
            </Button>
            <Button 
            href='https://jbrowse.potatogenomeportal.org/?config=chloro_config.json'
            variant="text" 
            color="secondary"
            size="large">
            Plastome Browser
        </Button>
      </ButtonGroup>
    </div>
  );
}