import React from "react"
import '../../App.css'
import View from '../JBrowse-component';
import "@fontsource/roboto"
import Footer from '../Footer'
import BasicButtonGroup from '../MultiButton'

function JBrowse() {
    return (
        <>
			<View/>
            <BasicButtonGroup/>
			<Footer/>
		</>
    )
}

export default JBrowse