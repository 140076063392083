import React from 'react'
import './Cards.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


function Showdata() {
  return (
    <>
      <div className='cards'>
        <h1>Download</h1>
      </div>
      <Typography component="div" style={{ backgroundColor: 'lightblue', height: '20px' }} />
      <Typography component="div" style={{ backgroundColor: 'transparent', height: '10px' }} />
        <Typography variant="h6" align="left">  
          <span> &nbsp;&nbsp;&nbsp;&nbsp; 
            Download Datasets
          </span>
        </Typography>
      <Typography component="div"/>
      <Typography component="div" style={{ backgroundColor: 'transparent', height: '20px' }} />
        <Typography variant="body2" align="left">  
          <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href='../annotations_23_potato_wild_relatives.tar.gz' download>annotations_23_potato_wild_relatives.tar.gz </a>
              <span> : <Box  component="span" sx={{ fontStyle: 'italic'}}>Annotations of 23 Potato Wild Relatives</Box> </span>
          </span>
          </Typography>
          <Typography variant="body2" align="left">  
          <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href='../repeats_23_potato_wild_relatives.tar.gz' download>repeats_23_potato_wild_relatives.tar.gz </a>
              <span> : <Box  component="span" sx={{ fontStyle: 'italic'}}>Repeats of 23 Potato Wild Relatives</Box> </span>
          </span>
        </Typography>
      <Typography component="div"/>

    </>
  );
}


export default Showdata
