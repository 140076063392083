
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const columns = [
  { id: 'name', label: 'Name', maxWidth: 20, align: 'left'},
  { id: 'code', label: 'Code', maxWidth: 50, align: 'center'},
  {
    id: 'identifier',
    label: 'Identifier',
    maxWidth: 50,
    align: 'center',
  },
  { id: 'ploidy', label: 'Ploidy', maxWidth: 10, align: 'center'},
  { id: 'mitochondria', label: 'Mitochondria Size', maxWidth: 10, align: 'center',format: (value) => value.toLocaleString('en-US')},
  { id: 'plastome', label: 'Plastome', maxWidth: 10, align: 'center'},
  // {
  //   id: 'size',
  //   label: 'Size\u00a0(km\u00b2)',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toLocaleString('en-US'),
  // },
  // {
  //   id: 'density',
  //   label: 'Density',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

function createData(name, code, identifier,ploidy,nuclear,mitochondria,plastome) {
  return { name, code, identifier,ploidy,nuclear,mitochondria,plastome};
}

const rows = [
  createData('Diploid potato clone H412-1', 'H412-1', 'AAFC', 'Diploid','','4 Molecules','155296'),
  createData('Diploid potato clone 08675-21', '08675-21', 'AAFC', 'Diploid','','4 Molecules','155296'),
  createData('Diploid potato clone 07506-01', '07506-01', 'AAFC', 'Diploid','','4 Molecules','155296'),
  createData('Diploid potato clone DW84-1457', 'DW84-1457', 'AAFC', 'Diploid','','4 Molecules','155296'),
  createData('Diploid potato clone 12120-03', '12120-03', 'AAFC', 'Diploid','','3 Molecules','155492'),
  createData('Diploid potato clone 11379-03', '11379-03', 'AAFC', 'Diploid','','3 Molecules','155492'),
  createData('Diploid potato clone 12625-02', '12625-02', 'AAFC', 'Diploid','','3 Molecules','155492'),
  createData('Diploid potato clone W5281.2', 'W5281.2', 'AAFC', 'Diploid','','3 Molecules','155492'),
  createData('Diploid potato clone 10908-06', '10908-06', 'AAFC', 'Diploid','','3 Molecules','155564'),
  createData('S. okadae', 'OKA15', 'AAFC', 'Diploid','','3 Molecules',''),
  createData('S.stenotomum ssp. goniocalyx', 'GON1', 'CIP 702472', 'Diploid','', '446946','155492'),
  createData('S.stenotomum ssp. goniocalyx', 'GON2', 'CIP 702472', 'Diploid','','446946','155492'),
  createData('S. xajanhuiri ', 'AJH', 'CIP 703810', 'Diploid','','450287','155486'),
  createData('S. phureja', 'PHU', 'CIP 703654', 'Diploid','','446946','155492'),
  createData('S. stenotomum subsp. stenotomum', 'STN', 'CIP 705834', 'Diploid','','446946','155492'),
  createData('S. bukasovii', 'BUK1', 'CIP 761748', 'Diploid','','446947','155491'),
  createData('S. bukasovii', 'BUK2', 'CIP 761748', 'Diploid','','429483','155584'),
  createData('S. tuberosum subsp. andigena ', 'ADG1', 'CIP 700921', 'Tetraploid','','478227','155530'),
  createData('S. tuberosum subsp. andigena ', 'ADG2', 'CIP 702853', 'Tetraploid','','474612','155518'),
  createData('S. curtilobum', 'CUR', 'CIP 702937', 'Pentaploid','','446162','155492'),
  createData('S. juzepczukii ', 'JUZ', 'CIP 706050', 'Triploid','','455624','155532'),
  createData('S. chaucha', 'CHA', 'CIP 707129', 'Triploid','','474615','155518'),
  createData('S. tuberosum subsp. tuberosum', 'TBR', 'CIP 705053', 'Tetraploid','','446162','155518'),
];

const useStyles = makeStyles({
  root: {
    minWidth: 300,
    maxWidth: '100%',
    padding: theme.spacing(20),
  },
  container: {
    maxHeight: 700,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[14, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

