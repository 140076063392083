import React from 'react';
import './Cards.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import imgCC from '../images/ComputeCanada_logo_0.gif'
import imgGC from '../images/genome-quebec.png'
import imgAAFC from '../images/AAFC-logo.png'
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
    minWidth: 345,
  },
  card2: {
    maxWidth: 500,
    minWidth: 500,
  },
  paper: {
    padding: theme.spacing(10),
    color: theme.palette.text.secondary,
  },
  media: {
    height: 200,
    width: '100%'
  },
}));

function Cards() {
  const [spacing] = React.useState(2);
  const classes = useStyles();
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="xl">        
          <Typography gutterBottom variant="h3" component="h2" align="center" style = {{ paddingTop: '5%'}}>
            Our Collaborators
          </Typography>
        </Container>
      </React.Fragment>
      <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
            <Grid item>
              <Card className={classes.card2}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    style = {{ height: 0, paddingTop: '25%'}}
                    image="../images/McGill_logo.gif"
                    title="McGill University"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      McGill University
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Strömvik Lab.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="https://mcgill.ca">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={imgAAFC}
                    title="AAFC"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      AAFC
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    The Department of Agriculture and Agri-Food, also referred to as Agriculture and Agri-Food Canada, is the department of the Government of Canada with responsibility for policies governing the production, processing, and marketing of all farm, food, and agri-based products.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="https://www.agr.gc.ca/">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="../images/CIP-logo.png"
                    title="International Potato Center"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      International Potato Center
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    CIP is a CGIAR research center, a global research partnership for a food-secure future.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="https://cipotato.org/">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    style = {{ height: 0, paddingTop: '42%'}}
                    image= {imgGC}
                    title="Génome Québec"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Génome Québec
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Génome Québec’s mission is to catalyze the development and excellence of genomics research and promote its integration and democratization.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="https://www.genomequebec.com/">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    style = {{ height: 0, paddingTop: '56%'}}
                    image= {imgCC}
                    title="Compute Canada"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Compute Canada
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    Compute Canada, in partnership with regional organizations ACENET, Calcul Québec, Compute Ontario and WestGrid, leads the acceleration of research and innovation by deploying state-of-the-art advanced research computing (ARC) systems, storage and software solutions.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="https://www.computecanada.ca/">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Container maxWidth="xl">        
      <Typography gutterBottom variant="h3" component="h2" align="center" style = {{ paddingTop: '5%'}} />
    </Container>
    </>
  );
}

export default Cards;