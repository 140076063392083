import React from 'react';
import Navbar from './componenets/Navbar';
import './App.css';
import Home from './componenets/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Collaborators from './componenets/pages/Collaborators';
import JBrowse from './componenets/pages/JBrowse';
import Galaxy from './componenets/pages/Galaxy';
import Download from './componenets/pages/Download';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/collaborators' component={Collaborators} />
          <Route path='/JBrowse' component={JBrowse} />
          <Route path='/galaxy' component={Galaxy} />
          <Route path='/download' component={Download} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
