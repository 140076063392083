import React from 'react'
import './Cards.css';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import imgP from '../images/CIP_potato_panel.jpeg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 1000,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  media: {
    height: 200,
    width: '100%'
  },
}));

function GalaxyComing() {
  const [spacing] = React.useState(2);
  const classes = useStyles();
  return (
    <>
      <div className='cards'>
        <h1>Upcoming Features</h1>
      </div>
      <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={imgP}
                    title="Galaxy Server"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                    PGDP - Galaxy Server
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    PGDP Galaxy Server is under constant development.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href='http://galaxy.potatogenomeportal.org'>
                    Visit PGDP Galaxy Server
                  </Button>
                </CardActions>
              </Card>
            </Grid>
        </Grid>
      </Grid>
      </Grid>
      <Container maxWidth="xl">        
        <Typography gutterBottom variant="h3" component="h2" align="center" style = {{ paddingTop: '5%'}} />
      </Container>
    </>
  );
}


export default GalaxyComing
