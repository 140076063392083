import React from 'react'
import '../../App.css';
import Showdata from '../Showdata';

function Download() {
    return (
        <div>
            <Showdata />
        </div>
    )
}

export default Download
