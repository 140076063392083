import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';



const theme = createMuiTheme();

const useStyles = makeStyles({
    custom: {
      color: "#00EE00",
      fontWeight: "bold",
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
      },
  });
  


function OwnCloudButton() {
    const classes = useStyles();
    return (
        <>
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lr">
                    <ThemeProvider theme={theme}>
                    <Typography component="div" style={{ backgroundColor: 'transparent', height: '80px' }} />
                    <Typography variant="h4" align="Left">
                        PGDP Data Transfer Platform
                    </Typography>
                    <Typography component="div" style={{ backgroundColor: 'transparent', height: '30px' }} />
                    <Typography variant="h6" align="left">  
                        Our collaborators can use our OwnCloud integration to upload and download files such as .fasta, .vcf, .gff, .bedpe, .bed, .bigwig, and .bam.
                    </Typography>
                    <Typography component="div" style={{ backgroundColor: 'transparent', height: '80px' }} />
                    </ThemeProvider>
                </Container>
            </React.Fragment>

            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" align="center" >
                    <Button variant="outlined" size="large" color="primary" className={classes.margin} style={{ height: '80px', width: '200px' }} href="https://data.potatogenomeportal.org">
                    OwnCloud
                    </Button>
                </Container>
            </React.Fragment>
        </>
    )
}

export default OwnCloudButton
