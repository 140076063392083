import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import OwnCloudButton from '../OwnCloudButton';


export default function Collaborators() {
  return(
  <>
    <OwnCloudButton />
    <Cards />
  </>
  );
}
