import React from 'react';
import '../App.css';
import { Button } from './Button';
import './PGDP.css';
import { Link } from 'react-router-dom';

function PGDP() {
  return (
    <div className='hero-container'>
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Potato Genome Diversity Portal</h1>
      <p>Welcome to PGDP</p>
      <div className='hero-btns'>
        <Link to='/JBrowse' className='btn-mobile'>
            <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--large'
            to='/JBrowse'
            >
            JBrowse
            </Button>
        </Link>
      </div>
    </div>
  );
}

export default PGDP;
