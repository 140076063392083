import React from 'react'
import '../../App.css';
import GalaxyComing from '../GalaxyComing';

function Galaxy() {
    return (
        <div>
            <GalaxyComing />
        </div>
    )
}

export default Galaxy
