import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import "@fontsource/roboto"
import {
  createViewState,
  createJBrowseTheme,
  JBrowseLinearGenomeView,
  ThemeProvider,
} from '@jbrowse/react-linear-genome-view'
import gon1 from "../potato-GON1/gon1_assembled_potato.fa.gz"
import gon1_fai from "../potato-GON1/gon1_assembled_potato.fa.fai"
import gon1_gzi from "../potato-GON1/gon1_assembled_potato.fa.gz.gzi"
import gon1_gff from "../potato-GON1/sorted.sorted_filtered_0.33AED_renamed.gon1_assembled_potato.all.gff.gz"
import gon1_gff_tbi from "../potato-GON1/sorted.sorted_filtered_0.33AED_renamed.gon1_assembled_potato.all.gff.gz.tbi"



const theme = createJBrowseTheme()

const assembly = {
  name: "gon1",
  sequence: {
    type: "ReferenceSequenceTrack",
    trackId: "gon1-ReferenceSequenceTrack",
    adapter: {
      type: "BgzipFastaAdapter",
      fastaLocation: {
        uri: gon1,
      },
      faiLocation: {
        uri: gon1_fai,
      },
      gziLocation: {
        uri: gon1_gzi,
      },
    },
  },
}

const tracks = [
    {
      type: 'FeatureTrack',
      trackId: 'gon1-FeatureTrack',
      name: 'RefSeq (GFF3Tabix)',
      assemblyNames: ['gon1'],
      category: ['Annotation'],
      adapter: {
        type: 'Gff3TabixAdapter',
        gffGzLocation: {
          uri:
          gon1_gff,
        },
        index: {
          location: {
            uri:
              gon1_gff_tbi,
          },
        },
      },
    },
  ]


const defaultSession = {
  name: 'GON1 Reference Genome',
  view: {
    id: 'linearGenomeView',
    type: 'LinearGenomeView',
    tracks: [
      {
        type: 'ReferenceSequenceTrack',
        configuration: 'gon1-ReferenceSequenceTrack',
        displays: [
          {
            type: 'LinearReferenceSequenceDisplay',
            configuration:
              'gon1-ReferenceSequenceTrack-LinearReferenceSequenceDisplay',
          },
        ],
      },
      {
        type: 'FeatureTrack',
        configuration: 'gon1-FeatureTrack',
        displays: [
          {
            type: 'LinearBasicDisplay',
            configuration: 'gon1-FeatureTrack-LinearBasicDisplay',
          },
        ],
      },
    ]
  }
}

function View() {
  const state = createViewState({
    assembly,
    tracks,
    location: 'gon1_pseudo_01:15,199,020..15,199,550',
    defaultSession
  })

  return (
    <>
      <ThemeProvider theme={theme}>
      <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography component="div" style={{ backgroundColor: 'transparent', height: '80px' }} />
        <Typography gutterBottom variant="h3" component="h3" align='center'>
        JBrowse
        </Typography>
        <Typography component="div" style={{ backgroundColor: 'transparent', height: '50px' }} />
      </Container>


      </React.Fragment>
      <JBrowseLinearGenomeView viewState={state} />
      </ThemeProvider>

      <CssBaseline />
      <Container maxWidth="md">
        <Typography component="div" style={{ backgroundColor: 'transparent', height: '80px' }} />
        <Typography gutterBottom variant="text" component="h2">
        To browse more reference genomes and the full version of the JBrowse instances please follow the links.
        </Typography>
      </Container>
    </>
  )
}

export default View;