import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


const theme = createMuiTheme();


function Paragraph() {

    return (
        <>
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lr">
                    <ThemeProvider theme={theme}>
                    <Typography component="div" style={{ backgroundColor: 'transparent', height: '80px' }} />
                    <Typography variant="h2" align="center">
                        PDGP
                    </Typography>
                    <Typography component="div" style={{ backgroundColor: 'transparent', height: '30px' }} />
                    <Typography variant="h6" align="center">  
                        There are currently fifty-six available genomes on the Portal that can be visualized using JBrowse2. 
                        A total of 12 Solanum species from CIP that broadly represent the current potato taxonomy and their mitogenomes and plastomes are available. 
                        Along with ten clones from AAFC, including 9 Solanum tuberosum clones and one solanum okadae clone. 
                        While the plastomes of all the ten clones from AAFC are available, mitogenomes will be made shortly available for browsing as well.
                    </Typography>
                    <Typography component="div" style={{ backgroundColor: 'transparent', height: '80px' }} />
                    </ThemeProvider>
                </Container>
            </React.Fragment>    
        </>
    )
}

export default Paragraph
