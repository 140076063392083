import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import PGDP from '../PGDP';
import Footer from '../Footer';
import Paragraph from '../Paragraph'
import StickyHeadTable from '../StickyHeadTable'


function Home() {
    return (
        <>
            <PGDP />
            <Paragraph />
            <StickyHeadTable />
            <Cards />
            <Footer />
        </>
    )
}

export default Home
